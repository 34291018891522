<template>
  <div class="mt-5">
    <div class="py-2 px-3 tableHeader">{{ job.title }}</div>

    <v-data-table
      :headers="headers"
      :items="formatedList"
      :loading="loading"
      item-key="id"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1 "
    >
      <template v-slot:[`item.name`]="{ item }">
        <span @click="goToProfile(item)" class="cursor-pointer">{{
          item.name
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";
import { mapMutations, mapState } from "vuex";

export default {
  name: "job",
  data() {
    return {
      applicationList: [],
      job: {},
      loading: false,
    };
  },
  computed: {
    ...mapState(["profileLinks"]),

    headers() {
      return [
        { text: this.$vuetify.lang.t("$vuetify.name"), value: "name" },
        {
          text: this.$vuetify.lang.t("$vuetify.experience"),
          value: "experience",
        },
        { text: this.$vuetify.lang.t("$vuetify.applied_on"), value: "date" },
        { text: this.$vuetify.lang.t("$vuetify.status"), value: "status" },
      ];
    },
    formatedList() {
      return this.applicationList.map((application) => {
        const {
          _id,
          created_at,
          status,
          candidate: { full_name, experience },
          candidate,
        } = application;

        return {
          id: _id,
          status,
          name: full_name,
          experience: experience || "--",
          date: UTC_TO_LOCAL(created_at, "ll"),
          candidate_id: candidate._id,
        };
      });
    },
  },
  methods: {
    ...mapMutations(["SET_PROFILE_LINKS"]),

    async fetchList() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/job/${this.$route.params.id}?w=applications.candidate`
        );

        const job = resp.data;
        const { applications } = job;

        this.job = job;
        if (this.$route.params.status === "new") {
          this.applicationList = applications.filter(
            (app) => app.status.toLowerCase() === "new"
          );
        } else {
          this.applicationList = applications;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    goToProfile(item) {
      const links = [...this.profileLinks];
      const index = links.findIndex((profile) => profile.id === item.id);
      if (index === -1) links.push(item);
      this.SET_PROFILE_LINKS(links);
      // this.$router.push(`/application/${item.candidate_id}`);
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
